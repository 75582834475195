(function ($, Drupal) {
  'use strict';

  var collectionsStickyFormatterV1 = {
    template: $('.collections-sticky-formatter-v1'),
    activeSect: 0,
    crntPanel: 0,
    TotalSects: 0,
    lastAnimation: null,
    quietPeriod: 500,
    deltaOfInterest: null,
    video_timeout_id: null,
    video_timeout_created: null
  };

  Drupal.behaviors.collectionsStickyFormatterV1 = {
    get: function (key) {
      return collectionsStickyFormatterV1[key];
    },

    set: function (key, val) {
      collectionsStickyFormatterV1[key] = val;
    },

    goDown: function (frame, $progress_counter) {
      var self = this;

      if (self.get('activeSect') < self.get('TotalSects')) {
        self.set('crntPanel', self.get('activeSect') + 1);
        frame.addClass('scrolling').scrollTo('.card-sticky-section:eq(' + self.get('crntPanel') + '):not(.card--video)', 600, {
          offset: 0,
          axis: 'y',
          easing: 'swing',
          onAfter: function () {
            self.set('activeSect', self.get('crntPanel'));
            frame.removeClass('scrolling');
            self.updateCounter($progress_counter);
          }
        });
      }
    },

    goUp: function (frame, $progress_counter) {
      var self = this;

      if (self.get('activeSect') > 0) {
        self.set('crntPanel', self.get('activeSect') - 1);
        frame.addClass('scrolling');
        frame.scrollTo('.card-sticky-section:eq(' + self.get('crntPanel') + '):not(.card--video)', 600, {
          offset: 0,
          axis: 'y',
          easing: 'swing',
          onAfter: function () {
            self.set('activeSect', self.get('crntPanel'));
            frame.removeClass('scrolling');
            self.updateCounter($progress_counter);
          }
        });
      }
    },

    updateCounter: function ($progress_counter) {
      var self = this;

      if ((self.get('activeSect') + 1) <= self.get('TotalSects')) {
        $progress_counter.html((self.get('activeSect') + 1) + '/' + self.get('TotalSects'));
      }
    },

    init_scroll: function (e, delta, frame, $progress_counter) {
      var self = this;

      self.set('deltaOfInterest', delta);
      var timeNow = new Date().getTime();

      // Cancel scroll if currently animating or within quiet period
      if (timeNow - self.get('lastAnimation') < self.get('quietPeriod') + 1000) {
        e.preventDefault();

        return;
      }
      if (self.get('deltaOfInterest') < 0) {
        self.goDown(frame, $progress_counter);
      } else {
        self.goUp(frame, $progress_counter);
      }
      self.set('lastAnimation', timeNow);
    },

    clear_video_timeout: function () {
      var self = this;

      window.clearTimeout(self.get('video_timeout_id'));
    },

    buildStickyCards: function (frame, $progress_counter) {
      var self = this;
      var sect = frame.find('.card-sticky-section');
      var fillH = frame.find('.fill');
      var scroll = frame.find('.scroll');
      var $body = $('body');
      var offsetValue = Modernizr.touch ? '100%' : '50%';

      $body.addClass('sticky-cards-ready');

      $(window).resize(function () {
        if ($body.hasClass('sticky-cards-ready')) {
          self.setCardHeight(frame, fillH, sect);
        }
      }).resize();

      // Waypoint Nav
      sect.waypoint(function (direction) {
        if ($body.hasClass('sticky-cards-ready')) {
          $body.toggleClass(this.element.id + '-visible', direction === 'down');
          $(this.element).addClass('init', direction === 'down');
          $(this.element).toggleClass('active', direction === 'down');
        }
      }, {
        offset: offsetValue,
        context: '.frame'
      });
      sect.waypoint(function (direction) {
        if ($body.hasClass('sticky-cards-ready')) {
          $body.toggleClass(this.element.id + '-visible', direction === 'up');
          $(this.element).addClass('init', direction === 'up');
          $(this.element).toggleClass('active', direction === 'up');
        }
      }, {
        offset: '-' + offsetValue,
        context: '.frame'
      });

      // Mousewheel
      if ($('.sticky-cards-page').is('*')) {
        if (Modernizr.touch === false) {
          $(document).bind('mousewheel DOMMouseScroll MozMousePixelScroll', function (e) {
            if ($body.hasClass('sticky-cards-ready')) {
              e.preventDefault();
              var delta = e.originalEvent.wheelDelta || -e.originalEvent.detail;

              if (delta === 0) {
                delta = e.deltaY;
              }
              self.init_scroll(e, delta, frame, $progress_counter);
            }
          });
        }
      }

      // Key Nav
      $(document.documentElement).keydown(function (e) {
        if ($body.hasClass('sticky-cards-ready')) {
          var direction = null;
          var key = e.keyCode ? e.keyCode : e.charCode;

          if (key === 40) { // DOWN
            self.goDown(frame, $progress_counter);
          } else if (key === 38) { // UP
            self.goUp(frame, $progress_counter);
          }
          if (direction !== null) {
            self.goDown(frame, $progress_counter);
          }

          // Prevent Default
          if (key >= 38 && key <= 40) {
            e.preventDefault();

            return false;
          }
        }
      });

      frame.scroll(function () {
        if ($body.hasClass('sticky-cards-ready')) {
        // Calculate distance scrolled
          if ($(this).scrollTop() > 0) {
            scroll.addClass('min');

          // Videos
          // var $video_section = frame.find('.card--video');
          // if ($video_section.hasClass('active') && ($body.hasClass('swim-video-visible'))) {
          //  var $this_video_section = frame.find('.card--video.active');
          //  var $video_section_video = $this_video_section.find('video');
          //  var $video_section_play = $this_video_section.find('.play');
          //  if (!$($video_section_video).hasClass('manual-pause')) {
          //    if (!$($video_section_video).parent().hasClass('ended')) {
          //      if (self.get('video_timeout_created') === false) {
          //        self.set('video_timeout_id', setTimeout(function () {
          //          $video_section_video[0].play();
          //          self.set('video_timeout_created', true);
          //        }, 3000));
          //      } else {
          //        $video_section_video[0].currentTime = 0;
          //        $video_section_video[0].play();
          //      }
          //      $video_section_play.addClass('active');
          //    }
          //  }
          // } else {
          //  self.clear_video_timeout();
          //  $video_section.find('video')[0].pause();
          //  $video_section.find('.play').removeClass('active');
          // }
          } else {
            $(scroll).removeClass('min');
          }
        }
      });

      $(window).on('sidewideBanner:up', function () {
        self.setCardHeight(frame, fillH, sect);
        frame.scrollTo('.card-sticky-section.active');
      });

      $(window).on('sidewideBanner:down:animationComplete', function () {
        self.setCardHeight(frame, fillH, sect);
      });

      $('.site-footer').hide();
    },

    setCardHeight: function (frame, fillH, sect) {
    // var fillH = frame.find('.fill');
    // var sect = frame.find('.card-sticky-section');

      var bannerAdjustment = $('html').hasClass('site-banner__is-open') ? $('.site-banner:not(".hidden")').height() : 0;

      sect.height($(window).height() - bannerAdjustment + 2);
      fillH.height($(window).height() - bannerAdjustment + 2);
    },

    destroyStickyCards: function (frame) {
      var self = this;
      var sect = frame.find('.card-sticky-section');
      var fillH = frame.find('.fill');
      var $body = $('body');

      $body.removeClass('sticky-cards-ready');

      sect.attr('style', '').removeAttr('style');
      fillH.attr('style', '').removeAttr('style');

      $('.site-footer').show();
    },

    attach: function (context, settings) {
      var self = this;
      var $template = $(self.get('template'), context);

      $template.each(function () {
        var $thisTemplate = $(this);
        var vidPlay = $thisTemplate.find('.vid, .play');
        var frame = $thisTemplate.find('.frame');
        var $progress_counter = $thisTemplate.find('.sticky-progress-counter');

        if (Modernizr.touch) {
          frame.addClass('touch-detected');
        }

        self.set('TotalSects', $thisTemplate.find('.card-sticky-section').not('.card--video').size());
        self.updateCounter($progress_counter);

        vidPlay.once().click(function () {
          var video = $(this).find('video');
          var playBtn = $(this).find('.play');
          var myVid = video.get(0);

          if (!myVid.paused) { // IF PLAYING
            $(myVid).removeClass('playing').addClass('manual-pause');
            myVid.pause();
            playBtn.removeClass('active');
          } else { // IF PAUSED
            if ($(video).parent().hasClass('ended')) {
              $(this).closest('.ended').removeClass('ended');
              $(this).removeClass('active');
            }
            $(myVid).addClass('playing').removeClass('manual-pause');
            myVid.play();
            playBtn.addClass('active');
          }
        });

        $thisTemplate.find('.play').once().click(function () {
          var video = $(this).prev('video');
          var playBtn = $(this);
          var myVid = video.get(0);

          if ($(this).closest('.vid').hasClass('ended')) {
            if (!myVid.paused) { // IF PLAYING
              $(myVid).removeClass('playing').addClass('manual-pause');
              myVid.pause();
              playBtn.removeClass('active');
            } else { // IF PAUSED
              if ($(video).parent().hasClass('ended')) {
                $(this).closest('.ended').removeClass('ended');
                $(this).removeClass('active');
              }
              $(myVid).addClass('playing').removeClass('manual-pause');
              myVid.play();
              playBtn.addClass('active');
            }
          }
        });

        $thisTemplate.find('video').bind('ended', function () {
          $(this).closest('.vid').addClass('ended');
          $(this).next('.play').removeClass('active');
        });

        Unison.on('change', function (bp) {
          var bpWidth = parseInt(bp.width, 10);
          var usnXLarge = parseInt(Unison.fetch.all()['usn-x-large'], 10);

          if (bpWidth >= usnXLarge) {
            self.buildStickyCards(frame, $progress_counter);
          } else {
            self.destroyStickyCards(frame);
          }
        });
      });
    }
  };
})(jQuery, Drupal);
